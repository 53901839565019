<template>
    <div class="booking-item-wrapper">
        <el-row class="booking-item-row" v-if="itemData.notice != null">
            <div class="booking-item" style="color:#ff0000;">
                {{ itemData.notice }}
            </div>
        </el-row>
        <el-row class="booking-item-row">
            <div class="booking-item">
                <div class="label">会议主题：</div>
                <div class="content">{{ itemData.title }}</div>
            </div>
            <div class="booking-item">
                <div class="label">审批状态：</div>
                <div class="content">
                  <div v-if="itemData.status == 0">
                    <span class="circle-dot" style="background-color:#e6a23c;"></span><span>待审批</span>
                  </div>
                  <div v-if="itemData.status == 1">
                    <span  class="circle-dot" style="background-color:#67c23a;"></span><span>预约成功</span>
                  </div>
                  <div v-if="itemData.status == 2">
                    <span  class="circle-dot" style="background-color:#e6a23c;"></span><span>已驳回</span>
                  </div>
                  <div v-if="itemData.status == 3">
                    <span  class="circle-dot" style="background-color:#909399;"></span><span>已取消</span>
                  </div>

                  <!--
                  <el-tag v-if="itemData.status == 0" type="warning" size="small">待审批</el-tag>
                  <el-tag v-if="itemData.status == 1" type="success" size="small">预约成功</el-tag>
                  <el-tag v-if="itemData.status == 2" type="danger" size="small">已驳回</el-tag>
                  <el-tag v-if="itemData.status == 3" type="info" size="small">已取消</el-tag>
                  -->
                </div>

            </div>
        </el-row>
        <el-row class="booking-item-row">
            <div class="booking-item">
                <div class="label">会议时间：</div>
                <div class="content">{{ parseDate(itemData.meetingDate) }} {{ itemData.startTime }} - {{ itemData.endTime }}</div>
            </div>
            <div class="booking-item">
                <div class="label">会议地点：</div>
                <div class="content">
                    <span>{{ itemData.meetingRoom.name }}</span>
                    <span style="color:#606266;">--{{ itemData.meetingRoom.building }}{{ itemData.meetingRoom.floor }}</span>
                </div>
            </div>
        </el-row>

        <el-row class="booking-item-row" v-show="!moreCollapse">
            <div class="booking-item">
                <div class="label">会议类型：</div>
                <div class="content">{{getMeetingTypeName(itemData.meetingType)}}</div>
            </div>
            <div class="booking-item">
                <div class="label">参会人数：</div>
                <div class="content">
                    <span>{{ itemData.totals }}人</span>
                    <span style="color:#606266;">(校内{{ itemData.innerstaff ? itemData.innerstaff : 0 }}人/校外{{
                        itemData.outerstaff ? itemData.outerstaff : 0
                      }}人/国际{{ itemData.foreignstaff ? itemData.foreignstaff : 0 }}人)</span>
                </div>
            </div>
        </el-row>
        <el-row class="booking-item-row" v-show="!moreCollapse">
            <div class="booking-item">
                <div class="label">布置需求：</div>
                <div class="content">{{ itemData.layoutDemands }}</div>
            </div>
            <div class="booking-item">
                <div class="label">会务需求：</div>
                <div class="content">{{ itemData.meetingDemands }}</div>
            </div>
        </el-row>
        <el-row class="booking-item-row" v-show="!moreCollapse">
            <div class="booking-item">
                <div class="label">预约人：</div>
                <div class="content">{{ itemData.user.userName }}</div>
            </div>
            <div class="booking-item">
                <div class="label">预约时间：</div>
                <div class="content">{{ parseDate(itemData.submitTime) }}</div>
            </div>
        </el-row>
        <el-row class="booking-item-row" v-if="itemData.checkTime" v-show="!moreCollapse">
            <div class="booking-item">
                <div class="label">审批人：</div>
                <div class="content">{{ itemData.checkUser.userName }}</div>
            </div>
            <div class="booking-item">
                <div class="label">审批时间：</div>
                <div class="content">{{ parseDate(itemData.checkTime) }}</div>
            </div>
        </el-row>
        <div class="check-description" v-if="moreCollapse==false && itemData.status == 2">
            <span class="el-icon-info">审批意见：</span>
            <span>{{ itemData.checkRemark }}</span>
        </div>
        <el-row class="booking-item-row" v-show="!moreCollapse && (itemData.status == 0 || itemData.status == 1)">
            <div class="cancel">
                <el-button plain
                    type="warning"
                    size="small"
                  @click="handleCancel(itemData)"
                >取消本次预约</el-button>
            </div>
        </el-row>
        <div class="more-collapse" @click="handleMoreCollapse">
            <span v-if="moreCollapse">查看全部会议信息</span>
            <span v-else>点击收起</span>
        </div>
    </div>
</template>
<script>
    import {getMeetingTypeName,} from "@/utils/constUtil";

    export default{
        props: {
            itemData: null,
        },
        data() {
            return {
                moreCollapse: true,
            }
        },
        methods: {
            getMeetingTypeName,

            parseDate(timestamp) {
            var date = new Date(timestamp * 1000);
            var Y = date.getFullYear();
            var M =
                date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
            var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

            return Y + "年" + M + "月" + D + "日";
            },

            handleMoreCollapse(){
                this.moreCollapse = !this.moreCollapse;
            },

            // 取消本地预约事件
            handleCancel(itemData){
                this.$emit("cancelBooking", itemData);
            },
        },
        created(){

        },
        mounted() {},
    }
</script>
<style scoped>
    .booking-item-wrapper {
        display: flex;
        flex-direction: column;
        border: 1px solid #DCDFE6;
        padding: 10px;
        border-radius: 5px;
        margin-top: 15px;
        box-sizing: border-box;
        background-color: #fff;
    }

    .booking-item-row{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
    }
    .booking-item-row .cancel{
      margin-top: 5px;
      padding-top: 10px;
      flex-grow: 1;
      text-align: right;
      border-top: 1px solid #EBEEF5;

    }

    .booking-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        flex: 0 0 50%;
        padding: 8px 0px;
    }

    .booking-item .label{
        flex: 0 0 80px;
        color: #909399;
    }

    .booking-item .content{
      display: flex;
      align-items: center;
    }

    .booking-item .circle-dot{
      display: block;
      float: left;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 5px;
    }


    .booking-item .cancel:hover{
        color: rgba(112, 0, 5, 0.8);
        cursor: pointer;
    }

    .check-description{
        background-color: rgb(250, 236, 216);
        font-size: 12px;
        line-height: 13px;
        padding: 5px;
        color: rgb(112, 0, 5);
    }

    .more-collapse{
        padding: 8px 0px;
        color: #606266;
        font-size: 11px;
        cursor: pointer;
        text-align: center;
    }

    @media screen and (max-width: 600px) {
    .booking-item-row{
        flex-flow: row wrap;
        flex-direction: row;
        box-sizing: border-box;
    }
    .booking-item{
        flex: 0 0 100%;

    }

}

</style>
