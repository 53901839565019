import { post, fetch, patch, put } from '@/utils/http';
const API_ROOT = process.env.VUE_APP_API_URL;

async function getMeetingRooms() {
    return post(`${API_ROOT}/meeting/getMeetingRoomList`);
}

async function getBookingDateRange() {
    return post(`${API_ROOT}/meeting/getBookingDateRange`);
}

async function getMeetingRoomById(roomId) {
    return post(`${API_ROOT}/meeting/getMeetingRoomDetail`, {
        meetingRoomId: roomId
    });
}

async function getMeetingRoomIntervals(roomId, bookingDate) {
    return post(`${API_ROOT}/meeting/getMeetingRoomIntervals`, {
        meetingRoomId: roomId,
        meetingDate: bookingDate,
    });
}

async function submitMeetingRoomOrder(bookingForm) {
    return post(`${API_ROOT}/meeting/submitMeetingRoomOrder`, bookingForm);
}

async function getMyOrders(page, size) {
    return post(`${API_ROOT}/meeting/getMyMeetingRoomOrderList`, {
        page: page,
        size: size,
    });
}

async function getOrders(status, page, size) {
    return post(`${API_ROOT}/meeting/getMeetingRoomOrderList`, {
        status: status,
        page: page,
        size: size,
        orderBy: 'checktime',
    });
}

async function searchOrderList(queryParam) {
  return post(`${API_ROOT}/meeting/getMeetingRoomOrderList`, {
      status: queryParam.status,
      page: queryParam.page,
      size: queryParam.size,
      startDate: queryParam.startDate,
      endDate: queryParam.endDate,
      roomIds: queryParam.roomIds,
      orderBy: 'checktime',
  });
}

async function queryMeetingRoomOrderList(queryParam) {
    return post(`${API_ROOT}/meeting/queryMeetingRoomOrderList`, queryParam);
  }

async function submitCheckInfo(orderId, status, remark) {
    return post(`${API_ROOT}/meeting/submitMeetingOrderCheckInfo`, {
        meetingRoomOrderId: orderId,
        status: status,
        remark: remark,
    });
}


async function submitBatchCheckInfo(orderIds, status, remark) {
  return post(`${API_ROOT}/meeting/batchCheckMeetingOrders`, {
      orderIds: orderIds!=null ? orderIds.join(",") : '',
      status: status,
      remark: remark,
  });
}

async function batchCheckAllOrders(status, remark) {
  return post(`${API_ROOT}/meeting/batchCheckAllOrders`, {
      status: status,
      remark: remark,
  });
}

async function cancelOrder(orderId) {
    return post(`${API_ROOT}/meeting/cancelMeetingRoomOrder`, {
        orderId: orderId,
    });
}

export {
    getMeetingRooms,
    getMeetingRoomIntervals,
    getMeetingRoomById,
    submitMeetingRoomOrder,
    getMyOrders,
    getOrders,
    submitCheckInfo,
    cancelOrder,
    getBookingDateRange,
    searchOrderList,
    submitBatchCheckInfo,
    batchCheckAllOrders,
    queryMeetingRoomOrderList,
}
