
import { post, fetch, patch, put } from '@/utils/http';
const API_ROOT = process.env.VUE_APP_API_URL;

async function loginByName(formData) {
    return post(`${API_ROOT}/loginByName`, formData);
}

async function ssologin(token) {
    return post(`${API_ROOT}/ssologin`, {
        token:token,
    });
}

async function logout(_this) {
    if(_this.$store){
        _this.$store.commit('account/changLogin', {
            isLogin: false,
            userInfo: null
        });
    }

    localStorage.setItem("access-token", null);
    // reqdata(`${UAS_PREFIX}/user/logout`, post).then((data)=>{
    //     localStorage.removeItem("access-token");
    //     localStorage.removeItem("refresh-token");
    //     _this.$store&&_this.$store.commit('account/setIsLogin', false);
    // });

    
}

export {
    loginByName,
    logout,
    ssologin,
}