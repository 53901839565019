import Vue from "vue";
import Router from "vue-router";

Vue.use(Router)

/*
const originPush = Router.prototype.push;
Router.prototype.push = function push(location){
  return originPush.call(this, location).catch(err => err);
}
  */

import Booking from "@/pages/booking";
import MyMeeting from "@/pages/mymeeting";
import Login from "@/pages/login";
import PkuLogin from "@/pages/pkulogin";
import Approve from "@/pages/approvelist";
import PkuSSO from "@/pages/pkusso";
import ArticleView from "@/pages/publicity/articleviewer";


const router = new Router({
  mode: "history",
  routes: [
    {
      path: '/',
      name: 'index',
      component: (resolve) => require(["@/pages/index"], resolve)
    },
    {
      path: '/booking',
      name: 'booking',
      component: Booking,
    },
    {
      path: '/login',
      name: 'login',
      component: PkuLogin,
      //component: Login,
    },
    {
      path: '/callback/pkusso',
      name: 'pkusso',
      component: PkuSSO,
    },
    {
      path: '/mylogin',
      name: 'mylogin',
      component: Login,
    },
    {
      path: '/mymeeting',
      name: 'mymeeting',
      component: MyMeeting,
    },
    {
      path: '/approve',
      name: 'approve',
      component: Approve,
    },
    {
      path: '/publicity',
      name: 'publicity',  
      component:(resolve) => require(['@/pages/publicity/index'], resolve)
    },
    /*
    {
      path: '/publicity/leader',
      name: 'Leader',
      component:() => import('@/pages/publicity/leader')
    },
    {
      path: '/publicity/policy',
      name: 'Policy',
      component:() => import('@/pages/publicity/policy')
    },
    {
      path: '/publicity/address',
      name: 'Address',
      component:() => import('@/pages/publicity/address')
    },
    {
      path: '/publicity/inspection',
      name: 'Inspection',
      component:() => import('@/pages/publicity/inspection')
    },    
    */
    {
      path: '/article',
      name: 'Article',
      component: ArticleView,
    },
    {
      path: '/reader',
      name: 'Reader',
      component:(resolve) => require(['@/pages/reader/index'], resolve)
    }
  ]
});

// 路由跳转前
router.beforeEach((to, from, next) => {
  // 可用于拦截导航并执行一些操作，例如验证用户身份、权限控制等。
  if(to.meta && to.meta.title){
    document.title = to.meta.title;
  }
  next();
});

// 路由跳转后
router.afterEach((to, from) => {
  window.scrollTo(0, 0); // 每次路由改变滚动条都回到顶部
});

export default router;
