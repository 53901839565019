<template>
  <div class="booking-container" v-if="hasBookingPermission">
    <div
      v-if="bookingRooms.length > 0">
      <MybookingItem v-for="itemData in bookingRooms"
        :itemData = "itemData"
        :key = "itemData.id"
        @cancelBooking="handleBookingCancel">

      </MybookingItem>
      <div class="loadmore-wrap">
        <el-button
          size="small"
          @click="handleLoadmore"
          :disabled="diabledLoadmore">{{loadmoreBtnText}}</el-button>
      </div>
    </div>
    <div v-else>
      <el-empty :image-size="100"></el-empty>
    </div>
  </div>
  <div v-else style="margin-top:50px;">
    <p>您还不能预约会议室，请与管理员联系。</p>
  </div>
</template>

<script>
import { getMyOrders, cancelOrder } from "@/api/meetingRoomApi";
import userUtil from "@/utils/userUtil";
import stringUtil from "@/utils/stringUtil";
import {getMeetingTypeName,} from "@/utils/constUtil";
import MybookingItem from "./mybookingItem.vue";

export default {
  components: {MybookingItem},
  props: {

  },
  data() {
    return {
      bookingRooms: [],
      userInfo: null,
      currPage: 1,
      totalPage: 0,
      pageSize: 10,

      diabledLoadmore: false,
      loadmoreBtnText: "加载更多",

      hasBookingPermission: true,
      immediateEnabled: false,
    };
  },
  methods: {
    getTypeName(type){
      return getMeetingTypeName(type);
    },
    isShow(item) {
      return item.status == 1 || item.status == 0 ? true : false;
    },

    isEmptyValue(val){
      return stringUtil.isEmptyString(val);
    },

    parseDate(timestamp) {
      var date = new Date(timestamp * 1000);
      var Y = date.getFullYear();
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

      return Y + "年" + M + "月" + D + "日";
    },
    loadMyBookingRooms(page, size) {
      getMyOrders(page, size).then(result => {
        if (result.code != 200 || result.data == null || result.data.list.length == 0) {
          return;
        }
        this.currPage = result.data.curr;
        this.totalPage = result.data.pages;
        if(this.currPage >= this.totalPage){
          this.diabledLoadmore = true;
          this.loadmoreBtnText = "没有更多数据了";
        }
        if(page === 1){
          this.bookingRooms = [];
        }
        //this.pageSize = result.data.size;
        this.bookingRooms.push(...result.data.list);
      });
    },

    handleLoadmore(){
      if(this.currPage < this.totalPage){
        let page = this.currPage + 1;
        this.loadMyBookingRooms(page, this.pageSize);
      }
    },

    handleBookingCancel(itemData){
      this.$confirm(
        "此操作将取消 " + itemData.meetingRoom.name + " 的预约, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          cancelOrder(itemData.id).then(() => {
            //this.bookingRooms = [];
            this.loadMyBookingRooms(1, this.pageSize);
            this.$message({
              type: "success",
              message: "取消成功!"
            });
          });
        })
        .catch(() => {});
    },

    loadMoreBooking() {
      //console.log("loadMoreBooking1: currPage=" + this.currPage + "  totalpage=" + this.totalPage);
      this.immediateEnabled = true;
      let page = (this.currPage += 1);
      if(this.currPage >= this.totalPage){
        return;
      }
      //console.log("loadMoreBooking 22222222");
      this.loadMyBookingRooms(page, this.pageSize);
    },

    initBookingRooms() {
      //this.bookingRooms = [];
      this.hasBookingPermission && this.loadMyBookingRooms(1, this.pageSize);
    },

    // 获取用户审核信息
    getUserInfo(){
      setTimeout(() => {
        this.userInfo = this.$store.getters['account/user'];
        if (userUtil.isRole(this.userInfo, "common")) {
          this.hasBookingPermission = false;
        }
      }, 200);
    },
  },
  created() {
    this.getUserInfo();
    /*
    let tab = this.$route.query.t;
    if (tab == "1") {
      setTimeout(() => {
        this.validatePermission();
        this.initBookingRooms();
      }, 200);
    } else {
      setTimeout(() => {
        this.checkBookingAccess();
        this.initBookingRooms();
      }, 200);
    }
    */

    this.initBookingRooms();
  },
  mounted() {}
}

</script>

<style scoped>
.booking-container {
  width: 100%;
  padding: 0px 10px;
}

.loadmore-wrap{
  margin-top: 10px;
  text-align: center;
}
@media screen and (max-width: 600px) {


}
</style>
<style>
@media screen and (max-width: 600px) {
  .el-message-box {
    width: auto !important;
  }
}
</style>
