<template>
  <el-footer class="footer">
        <p>©copyright 2023 北京大学王选计算机研究所</p>
  </el-footer>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style scoped>
.footer{
    height: 50px;
    width: 100%;
    text-align: center;
    background:#302a29;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    font-size: 12px;
    margin-top: 50px;
}
</style>
