<template>
  <div class="main-container">    
    <Header></Header>
      <div class="content-container">
        <div class="booking-body">
          <el-steps :space="200" :active="activeStep" finish-status="success">
            <el-step title="选择时段"></el-step>
            <el-step title="提交预约"></el-step>
            <el-step class="last-step" title="完成"></el-step>
          </el-steps>
          <component
            :is="comName"
            :bookingRoom="bookingRoom"
            :bookingDate="bookingDate"
            :bookingTime="bookingTime"
            @gotoStep2="gotoStep2"
            ref="bookingTab"
          ></component>
        </div>
      </div>
    
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import SelectTime from "@/components/booking/selecttime";
import BookingForm from "@/components/booking/bookingform";
import { getMeetingRoomById } from "@/api/meetingRoomApi";
export default {
  components: { Header, Footer, SelectTime, BookingForm },
  props: {},
  data() {
    return {
      comName: SelectTime,
      bookingRoom: null,
      bookingDate: null,
      bookingTime: null,
      activeStep: 1,
      userInfo: null,
      selectedRoom: null
    };
  },
  methods: {
    gotoStep2(room, bookingDate, bookingTime) {
      this.bookingRoom = room;
      this.bookingDate = bookingDate;
      this.bookingTime = bookingTime;
      this.comName = BookingForm;
      this.activeStep = 2;
    },
    
    loadRoom() {
      let roomId = this.$route.query.id;
      getMeetingRoomById(roomId).then(result => {
        this.selectedRoom = result.data;
        this.$refs['bookingTab'].setForBookingRoom(this.selectedRoom);
      });
    }
  },
  created() {
    /*
    setTimeout(() => {
      this.userInfo = this.$store.getters['account/user'];
      if (!this.userInfo) {
        this.$router.push("/login");
        return;
      }
      this.loadRoom();
    }, 200);
    */
    this.$nextTick(()=>{
      this.loadRoom();
    });
    
  },
  mounted() {}
};
</script>

<style scoped>
/deep/.el-steps--horizontal {
  width: 100%;
  display: flex;
  justify-content: center;
}
/deep/.el-steps--horizontal {
  display: flex;
  justify-content: center;
}
.last-step {
  flex-basis: 50px !important;
}
.booking-wrapper {
  min-height: 100%;
  display: flex;
  justify-content: center;
}
.booking-body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}
</style>
