<template>
    <div>
      
        <div class="tips">
            <div style="text-align: center;">
                正在登录王选所内部信息系统...
            </div>
        </div>                    
    </div>
</template>
<script>    
    import { getCurrentUserInfo } from "@/api/userApi";
    import { ssologin } from "@/api/loginApi";
    import $ from "jquery";
export default {  
  props: {},
  data() {
    return {      
    };
  },
  methods: {
    getUser() {
      getCurrentUserInfo().then(res => {
        if (res.code != 200) {
          return;
        }
        this.$store.commit("account/changLogin", {
          isLogin: true,
          userInfo: res.data.user
        });

        // setTimeout(()=>{
          this.$router.push("/");
        // }, 500);
        
      });
    },

    autoLogin(){
        let token = this.$route.query.token;
        if(token != null){
            ssologin(token).then(result => {              
                if (result.code == 200) { 
                    localStorage.setItem("access-token", result.data.access_token);
                    this.getUser();
                }else{
                    this.$message.error(result.msg);
                    this.$router.push("/login");
                    return;
                }                
            });
        }else{            
            this.$router.push("/login");
        }
    },
  },
  created() {    
    //this.autoLogin();
    
    setTimeout(() => {
      this.autoLogin();
    }, 200);    
  },
  mounted() {}
};
</script>
<style scoped>
    .tips{
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>