<template>
    <div class="main-container">
        <Header></Header>
        <div class="content-container">
            <MyBooking></MyBooking>
        </div>

        <Footer></Footer>
    </div>
</template>
<script>
    import Header from "@/components/header";
    import Footer from "@/components/footer";
    import MyBooking from "@/components/mybooking";
    import userUtil from "@/utils/userUtil";

    export default {
        components: {Header, Footer, MyBooking},
        props: {},
        data() {
            return {
                userInfo: null,
            };
        },
        methods: {
            // 获取用户审核信息
            getUserInfo(){
              setTimeout(() => {
                this.userInfo = this.$store.getters['account/user'];
                if (this.userInfo == null) {
                    this.$router.push("/login");
                }

                //if (userUtil.isRole(this.userInfo, "common")) {
                  //this.hasBookingPermission = false;
                //}

              }, 500);
            },

        },
        created() {
            this.$nextTick(()=>{
                //this.getUserInfo();
            });
        },
        mounted() {
            //this.getUserInfo();
        },
        watch: {
          /*
            "$store.state.account.user": {
                deep: true,
                handler(newVal, oldVal) {
                    this.userInfo = newVal;
                    if (this.userInfo == null) {
                        this.$router.push('/login');
                    }
                },
                immediate: true
            }
          */
        },
}
</script>
<style scoped>

</style>
