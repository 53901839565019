<template>
    <!--文章详情页-->
    <div class="main-container">
        <Header></Header>
        <div class="content-container" style="width:100%;">          
          <!-- 内容区域 -->
          <div class="sub-content-container">
            

           <div class="content-box">                
                <ArticleDetail :articleId="articleId" :showBreadcrumb="true"></ArticleDetail>
           </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
</template>
<script>
    import Header from "@/components/header";
    import Footer from "@/components/footer";
    import ArticleDetail from "./components/articledetail";

    export default {
        components: {Header, ArticleDetail, Footer },
        props: {
            
        },
        data() {
            return {
                articleId: undefined,
                
            };
        },
        methods: {
            
        },
        created() {              
            
            this.articleId = this.$route.query.id;
           
        },
        mounted() {
            
        },
        watch: {            
        },
}
</script>
<style scoped>
.subchannel-container{
    display: flex;
    flex-direction: column;    
}

.sub-content-container{    
    display: flex;
    flex-direction: column;
    align-items: center;    
    padding: 10px;    
}
.content-box, .breadcrumb-box{
    width: 800px;
}
.breadcrumb-box{
    justify-content: left;
}
</style>
