<template>
  <div class="booking-step-container">
    <div class="basic-info">
      <div>
        <span>会议室：</span>
        <div class="detail">
          <span>{{ bookingRoom.name }}</span
          ><i>/{{ bookingRoom.building }}{{ bookingRoom.floor }}</i>
        </div>
      </div>
      <div>
        <span>预约时间：</span>
        <div class="detail">
          <span>{{ parseDate(bookingDate) }}</span>
          <span v-if="bookingTime.second"
            >{{ bookingTime.first.startTime }}-{{
              bookingTime.second.endTime
            }}</span
          >
          <span v-else
            >{{ bookingTime.first.startTime }}-{{
              bookingTime.first.endTime
            }}</span
          >
        </div>
      </div>
    </div>
    <el-form :model="bookingForm" :rules="rules" ref="bookingForm">
      <div class="form-info">
        <div style="color:#606266;">请填写会议信息</div>
        <div>
          <span>会议类型：</span>
          <div class="info-main">
            <el-radio-group v-model="bookingForm.meetingType">
              <el-radio :label="0">周期性讨论班</el-radio>
              <el-radio :label="1">学术会议</el-radio>
              <el-radio :label="3">所内部工作会议</el-radio>
              <el-radio :label="4">所内业务交流（限所内老师）</el-radio>
              <el-radio :label="2">其他会议</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div>
          <span>会议主题：</span>
          <div class="info-main meeting-title-wrapper">
            <el-form-item prop="title" label-width="0">
              <el-input v-model="bookingForm.title"></el-input><i>（必填）</i>
            </el-form-item>
          </div>
        </div>
        <div>
          <span>参会人数：</span>
          <div class="info-main attend-number-wrapper">
            <el-input
              v-model="bookingForm.totals"
              type="number"
              min="1"
            ></el-input>
            <div>
              <span>其中，校内</span>
              <el-input

                v-model="bookingForm.innerStaff"
                type="number"
                min="0"
              ></el-input>
            </div>
            <div>
              <span>校外</span>
              <el-input

                v-model="bookingForm.outerStaff"
                type="number"
                min="0"
              ></el-input>
            </div>
            <div>
              <span>国际</span>
              <el-input
                v-model="bookingForm.foreignStaff"
                type="number"
                min="0"
              ></el-input>
            </div>
            <i>（必填）</i>
          </div>
        </div>
        <div class="align-start">
          <span>会场布置：</span>
          <div class="info-main">
            <el-input
              type="textarea"
              :rows="4"
              v-model="bookingForm.layoutDemands"
            >
            </el-input>
          </div>
        </div>
        <div class="align-start">
          <span>会务需求：</span>
          <div class="info-main">
            <el-input
              type="textarea"
              :rows="4"
              v-model="bookingForm.meetingDemands"
            >
            </el-input>
          </div>
        </div>
        <div class="tips" v-show="!isEmptyString(bookingForm.brief)">          
          <el-alert
            style="width: 80%;"
            title="说明"
            type="info"
            :closable="false"
            center
            show-icon>
            <template slot="title"><div>{{bookingForm.brief}}</div></template>            
          </el-alert>
        </div>
      </div>
    </el-form>

    <div class="submit-wrapper">
      <el-button type="danger" class="submit-btn" @click="handleSubmit"
        >提交会议预约</el-button
      >
    </div>
  </div>
</template>

<script>
import { submitMeetingRoomOrder, } from "@/api/meetingRoomApi";
export default {
  props: {
    bookingRoom: null,
    bookingDate: null,
    bookingTime: null
  },
  data() {
    return {
      bookingForm: {
        meetingRoomId: null,
        meetingDate: null,
        startTime: null,
        endTime: null,
        title: null,
        meetingType: 0,
        totals: 1,
        innerStaff: 0,
        outerStaff: 0,
        foreignStaff: 0,
        layoutDemands: null,
        meetingDemands: null,
        brief: null,
      },
      rules: {
        title: [
          { required: true, message: "请输入会议主题", trigger: "blur" },
          {
            required: true,
            validator: (field, value, cb) => {
              this.isBlank(field, value, cb, "请输入会议主题");
            },
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    parseDate(mdate) {
      return this.dateFormat(mdate);
    },
    dateFormat(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var d = date.getDay();
      var weekday = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      ];
      var time =
        year.toString() +
        "年" +
        month.toString() +
        "月" +
        day.toString() +
        "日   " +
        weekday[d];

      return time;
    },
    handleSubmit() {
      this.$refs["bookingForm"].validate(valid => {
        if (valid) {
          submitMeetingRoomOrder(this.bookingForm).then((result)=>{
            if (result.code != 200) {
              this.$message.error(result.msg);
              return;
            }
            this.$router.push("/mymeeting");
          });
        } else {
          return false;
        }
      });
    },
    isBlank(field, value, cb, msg){
      var result = !value || /^\s*$/.test(value);
      if (result) {
        cb(new Error(msg));
      } else {
        cb();
      }
    },
    parseTimeStamp(date){
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      let ts = date.getTime();

      return Math.floor(ts/1000);
    },

    isEmptyString(str){
      if(str == null || str.length == 0){
        return true;
      }
      return false;
    }
  },
  created() {
    //console.log(this.bookingTime);
    this.bookingForm.meetingRoomId = this.bookingRoom.id;
    this.bookingForm.meetingDate = this.parseTimeStamp(this.bookingDate);

    this.bookingForm.startTime = this.bookingTime.first.startTime;
    if (this.bookingTime.second) {
      this.bookingForm.endTime = this.bookingTime.second.endTime;
    } else {
      this.bookingForm.endTime = this.bookingTime.first.endTime;
    }
    this.bookingForm.brief = this.bookingRoom.brief;
  },
  mounted() {
    var self = this;
    document.onkeydown = function(e) {
      let ev = document.all ? window.event : e
      if (ev.keyCode === 13) {
        self.handleSubmit()
      }
    }
  }
};
</script>

<style scoped>
.booking-step-container {
  width: 100%;
}
.basic-info {
  margin-top: 20px;
  color: #606266;
}
.info-main {
  display: inline-flex;
  flex: 1;
}
.info-main i {
  font-size: 12px;
  width: 60px;
  display: flex;
  align-items: center;
}
.form-info > div {
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
}
/deep/.el-input input,
/deep/.el-textarea textarea {
  color: #000 !important;
  font-family: 'Microsoft YaHei' !important;
}
.meeting-title-wrapper {
  width: 92%;
}
.attend-number-wrapper .el-input {
  width: 80px !important;
  min-width: 80px;
}
.attend-number-wrapper > div:not(.el-input) {
  margin-left: 5px;
}
.attend-number-wrapper .el-input__inner {
  text-align: center !important;
}
.attend-number-wrapper span {
  display: inline-flex;
  align-items: center;
  margin: 0px 5px 0px 15px;
}
.basic-info,
.form-info {
  border: 1px solid #e4e7ed;
  padding: 15px;
}
.align-start {
  align-items: start !important;
}
.form-info {
  margin-top: 20px;
}
.form-info .tips {
  color:#606266;
  font-size:12px;
  display: flex;
  justify-content: center;
}
.meeting-title-wrapper .el-form-item {
  width: 100%;
  margin-bottom: 0px !important;
}
/deep/.meeting-title-wrapper .el-form-item .el-form-item__content {
  display: flex !important;
}
.basic-info i {
  color: #606266;
  margin-left: 20px;
  font-size: 12px;
}
.basic-info > div:last-child {
  margin-top: 15px;
  line-height: 1.5;
}
.detail {
  color: #333333;
  display: inline-block;
}
.submit-btn {
  background: rgba(148, 8, 11, 1);
  width: 200px;
  outline: none;
  border: none;
}
.submit-btn:hover {
  opacity: 0.8;
}
.submit-wrapper {
  margin-top: 20px;
  text-align: center;
}

/deep/.el-radio {
    margin: 10px 15px 10px 0px !important;
  }

@media screen and (max-width: 600px) {
  .form-info > div {
    align-items: start !important;
  }
  /deep/.el-radio-group {
    display: inline-flex;
    flex-direction: column;
    align-items: start;
  }
  /deep/.el-radio {
    margin: 5px 0px 10px 0px !important;
  }
  .meeting-title-wrapper .el-input,
  .attend-number-wrapper > .el-input {
    width: 100% !important;
  }
  .attend-number-wrapper > div:not(.el-input) {
    display: flex;
    margin-top: 10px;
  }
  .attend-number-wrapper > div span {
    margin: 0px 5px 0px 0px;
  }
  .info-main {
    flex-wrap: wrap;
  }
}
</style>
