<template>    
    <div class="main-container">
        <Header></Header>
      <div class="content-container" style="width:100%;">
        <Approve></Approve>
      </div>
      <Footer></Footer>
    </div>
</template>
<script>
    import Header from "@/components/header";
    import Footer from "@/components/footer";
    import Approve from "@/components/approve";
    export default {
        components: {Header, Footer, Approve},
        props: {},
        data() {
            return {
                userInfo: null,
            };
        },
        methods: {            
            checkLogin2(){
                setTimeout(() => {
                this.userInfo = this.$store.getters['account/user'];
                //console.log(this.userInfo);
                if (this.userInfo == null) {
                    this.$router.push("/login");
                }
              }, 500);
            },
        },
        created() {

        },
        mounted() {
          
        },
        watch: {
            /*
            "$store.state.account.user": {
                deep: true,
                handler(newVal, oldVal) {
                    this.userInfo = newVal;
                    if (this.userInfo == null) {
                        this.$router.push('/login');
                    }
                },
                immediate: true
            }
            */
        },
}
</script>
<style scoped>

</style>
