import { getCurrentUserInfo } from "@/api/userApi";
export default {
  namespaced: true,
  state: {
    user: undefined,
    isLogin: false,
  },
  getters: {
    user: state => {
      return state.user
    },
    isLogin: state=>{
      return state.isLogin;
    },

  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    changLogin(state, payload) {

      state.isLogin = payload.isLogin;
      state.user = payload.userInfo;
    },
    setIsLogin(state, flag){
      state.isLogin = flag ? true : false;
    }
  },
  actions: {
    checkLogin(context, payload) {
      const token = encodeURIComponent(localStorage.getItem("access-token"));
      if(!token){
        return;
      }
      getCurrentUserInfo().then(res => {
        if(res.code != 200){
          return;
        }

        context.commit('changLogin', {
          isLogin: true,
          userInfo: res.data.user,
        })
      })

    }
  },
}
