<template>
  <el-header id="page-header">
    <div class="go-back pull-left">
      <a href="/" class="text-info">
        <img
          class="profile_avatar left"
          :src="require('@/assets/img/logo.png')"
        />
        <span class="logo-text">王选所内部信息系统</span>
      </a>
    </div>
    <el-menu
      :default-active="activeIndex"
      mode="horizontal"
      :router="true"
      text-color="#fff"
      active-text-color="#fff"
      background-color="#700005"
      @select="handleMenuSelect"
    >
      <el-menu-item index="/publicity">信息公开</el-menu-item>
      <el-menu-item index="/">会议室预约</el-menu-item>
      <el-menu-item index="/mymeeting">我的预约</el-menu-item>
      <el-menu-item index="/approve" v-if="hasCheckPermission == true"
        >预约审批</el-menu-item
      >
    </el-menu>

    <ul class="user-state navbar-nav" v-if="userInfo ? false : true">
      <li>
        <a class="loginbtn" href="/login">登录</a>
      </li>
    </ul>
    <ul class="user-state navbar-nav" v-else>
      <li class="user-avatar-box">
        <el-avatar
          size="small"
          :src="require('@/assets/img/avatar-default.png')"
        ></el-avatar>
      </li>
      <li class="user-name-box">
        <span>{{ userInfo.nickName }}</span>
      </li>
      <li>
        <a @click="logout">退出</a>
      </li>
    </ul>
  </el-header>
</template>

<script>
import { getCurrentInfo, logout } from "@/api/loginApi";
import userUtil from "@/utils/userUtil";
export default {
  props: {
    forceLogin: { default: true },
  },
  data() {
    return {
      userInfo: null,
      activeIndex: "/",
      hasCheckPermission: false,
      isLogin: false,
    };
  },
  methods: {
    // 获取用户审核信息
    getUserInfo() {
      setTimeout(() => {
        this.userInfo = this.$store.getters["account/user"];
        // 检查登录状态
        if (this.forceLogin && this.userInfo == null) {
          this.$router.push("/login");
        }
        //console.log(localStorage.getItem("access-token"));
        if (this.userInfo) {
          this.isLogin = true;
          if (userUtil.isRole(this.userInfo, "checkuser")) {
            this.hasCheckPermission = true;
          }
        }
      }, 300);
    },

    logout() {
      logout(this);
      //this.$emit("logout");
      this.userInfo = null;
      this.hasCheckPermission = false;
      this.$router.push("/");
    },

    handleMenuSelect(index, indexPath) {
      this.activeIndex = index;
      this.$router.push({
        path: this.activeIndex,
      });
    },
  },
  created() {
    this.$nextTick(() => {
      // 获得当前登录用户
      this.getUserInfo();

      this.activeIndex = this.$route.path;
      //this.checkLogin(this.activeIndex);
      if (this.activeIndex == "/booking") {
        this.activeIndex = "/";
      } else if (this.activeIndex.indexOf("publicity") != -1) {
        this.activeIndex = "/publicity";
      }

      let source = this.$route.query.from;
      if (source != undefined && source.indexOf("publicity") != -1) {
        this.activeIndex = "/publicity";
      }      
    });
  },
  mounted() {
    //this.getUserInfo();
  },
  watch: {
    /*
    "$store.state.account.user": {
      deep: true,
      handler(newVal, oldVal) {
        this.userInfo = newVal;
        if (this.userInfo) {
          this.getUserInfo();
        }
      },
      immediate: true
    }
    */
  },
};
</script>

<style scoped>
#page-header {
  transition: all 0.3s;
  top: 0;
  background: rgb(112, 0, 5);
  width: 100%;
  height: 60px;
  line-height: 60px;
  z-index: 111;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  position: fixed;
}
.page-nav li div:hover {
  background: #f0f0f8;
}
.go-back {
  color: #ffffff;
}
.pull-left {
  float: left !important;
  margin-left: 40px;
}
#page-header a,
#page-header a.btn {
  color: #ffffff;
  display: block;
  text-align: center;
}
.user-state {
  padding: 0px;
  margin: 0px 60px 0 0px !important;
}
#page-header a img {
  max-width: 100px;
  max-height: 32px;
  vertical-align: middle;
}
.page-nav {
  position: initial;
  width: 300px;
  height: 100%;
  margin: 0 -moz-calc((100%)/2 - (131px + 200px));
  margin: 0 -webkit-calc((100%)/2 - (131px + 200px));
  margin: 0 calc((100%) / 2 - (131px + 200px));
  margin-left: 0px;
  float: left;
}
.page-nav li {
  display: table-cell;
}
.page-nav li div {
  border-radius: 5px;
  height: 40px;
  display: inline;
  padding: 10px 12px;
  cursor: pointer;
}
#page-header i {
  position: relative;
  vertical-align: middle;
  font-size: 16px;
  padding-right: 5px;
  margin-left: 5px;
}

.user-state.navbar-nav {
  float: right;
  height: 60px;
  display: flex;
  align-items: center;
}
.user-state.navbar-nav > li {
  font-weight: 400;
  font-size: 14px;
  list-style: none;
  line-height: 50px;
}
.user-state.navbar-nav .btn {
  width: 80px;
  height: 36px;
  background-color: #fff;
  font-size: 14px;
}
.navbar-nav > li {
  float: left;
}
.user-state.navbar-nav > li {
  display: flex;
  color: #fff;
  margin-left: 10px;
}

.el-avatar {
  background: rgb(112, 0, 5);
}

.el-menu--horizontal > .el-menu-item {
  font-size: 16px;
}

.el-menu--horizontal > .el-menu-item.is-active {
  background-color: #ad4e00 !important;
  border-bottom: none;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .user-state {
    margin: 0px 5px 0px 0px !important;
  }
  .pull-left {
    margin-left: 0px;
  }
  .logo-text {
    display: none;
  }
  .user-state.navbar-nav .user-name-box,
  .user-state.navbar-nav .user-avatar-box {
    display: none;
  }

  /deep/.el-header {
    padding: 0px 15px;
  }
  /deep/ .el-menu-item {
    padding: 0 10px;
  }
}
</style>
