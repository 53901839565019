import {  post, fetch, patch, put, downloadAsFile} from '@/utils/http';
const API_ROOT = process.env.VUE_APP_API_URL + "/cms";

async function getChildChannelByAlias(alias) {
    return fetch(`${API_ROOT}/channel/getChildChannelByAlias?alias=` + alias);
}

async function getChannelByAlias(alias) {
    return fetch(`${API_ROOT}/channel/getChannelByAlias?alias=` + alias);
}

async function getFullArticle(articleId) {
    return fetch(`${API_ROOT}/article/getFullArticle?articleId=` + articleId);
}


async function getArticleByAlias(alias) {
    return fetch(`${API_ROOT}/article/getArticleByAlias?alias=` + alias);
}

async function queryArticleList(queryParams) {
    return post(`${API_ROOT}/article/queryArticleList`, queryParams);
}

async function getArticleFile(fileId){
    return fetch(`${API_ROOT}/article/getArticleFile?fileId=` + fileId);
}


async function downloadArticleFile(fileId){
    //return fetch(`${API_ROOT}/article/downloadArticleFile?fileId=` + fileId);
    return downloadAsFile(`${API_ROOT}/article/downloadArticleFile`, {
        fileId: fileId        
      });
}

export {
    getChildChannelByAlias,
    getChannelByAlias,
    getFullArticle,
    getArticleByAlias,
    queryArticleList,
    getArticleFile,
    downloadArticleFile,
    
}