
// 审批状态常量
const WMEET_CHECK_STATUS_TODO = 0;
const WMEET_CHECK_STATUS_DONE = 1;
const WMEET_CHECK_STATUS_REFUSED = 2;
const WMEET_CHECK_STATUS_CANCEL = 3;

export{
  WMEET_CHECK_STATUS_TODO,
  WMEET_CHECK_STATUS_DONE,
  WMEET_CHECK_STATUS_REFUSED,
  WMEET_CHECK_STATUS_CANCEL,
}
