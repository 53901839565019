<template>    
    <!-- 文章内容展示组件 -->
    <div class="article-detail-container">
        <template v-if="articleData.articleId == undefined || articleData.articleId == null">
            <el-empty :image-size="180" description="没获取到文章数据，可能是数据配置错误"></el-empty>
        </template>
        <template v-else>
            <div class="breadcrumb-box" v-if="showBreadcrumb == true">
                <!--路径面包屑-->
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>                
                    <el-breadcrumb-item :to="{ path: subChannelPath }">{{ subChannelName }}</el-breadcrumb-item>
                    <el-breadcrumb-item>详情：{{articleData.title}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="title-box">
                <div class="title">{{articleData.title}}</div>
                <div class="pubtime">发布时间：{{ parseTime(articleData.pubTime, '{y}-{m}-{d}') }}</div>
            </div>
            <div class="content-box">
                <div class="paragraph-list" v-html="articleData.content"></div>
            </div>

            <div class="filelist-box" v-if="articleFileList && articleFileList.length > 0">
                <div class="filelist-box-title">
                    <div>附件：</div>
                </div>
                <div class="filelist-item" v-for="file in articleFileList"
                    :key="file.fileId">
                    <div class="file-title"><el-link @click="viewFile(file)" :underline="false">{{file.title}}</el-link></div>
                    <div class="file-op">
                        <el-link icon="el-icon-view" @click="viewFile(file)" :underline="false">浏览</el-link>
                        <el-link v-if="file.downloaded == 1" icon="el-icon-download" :underline="false" :href="'/oss/'+file.filePath" :download="file.originalName" target="_blank">下载</el-link>
                    </div>                    
                </div>
            </div>
        </template>
    </div>
</template>
<script>
    import {getFullArticle, downloadArticleFile} from "@/api/cms";
    import { getPathByChannelAlias} from "@/utils/cmsUtil";
    import { parseTime } from "@/utils/wmeeting";
    //import { download } from '@/utils/http';
    
    export default {
        components: { },
        props: {
            articleId: String,
            showBreadcrumb: {
                Type: Boolean,
                default: true
            }
        },
        data() {
            return {
                articleData: {},
                articleFileList: [],
                channelData: {},
                subChannelPath: "",
                subChannelName: undefined,
            };
        },
        methods: {
            parseTime,
            getArticle(id){
                if(id == undefined || id == null){
                    this.articleData = {};
                    return;
                }
                getFullArticle(id).then((res)=>{
                    //console.log(res);
                    if(res.code == 200 && res.data != null){
                        this.articleData = res.data.article;
                        this.articleFileList = res.data.fileList;
                        this.channelData = res.data.channel;

                        //this.subChannelPath = getPathByChannelAlias(this.channelData.alias);
                        this.subChannelPath = "/publicity?id=" + this.channelData.channelId;
                        this.subChannelName = this.channelData.name;
                    }else{
                        this.articleData = {};
                        this.articleFileList = [];
                        this.channelData = {};
                        this.subChannelPath = "";
                        this.subChannelName = undefined;
                    }
                });
            },

            viewFile(file){
                //console.log(file);
                //window.open("/oss/" + file.filePath, '_blank');
                window.open("/reader?fileId=" + file.fileId, '_blank');
            },

            // 下载文件
            downloadFile(file){
                //console.log("downloadFile=", file);
                
                downloadArticleFile(file.fileId).then(()=>{
                    this.$message.success("文件下载成功，默认保存在浏览器下载目录。");
                }).catch((er)=>{
                    this.$message.error("下载文件发生异常，或者不允许下载。");
                });
                
               /*
                download('cms/article/downloadArticleFile', {
                    fileId: file.fileId
                }, `111.pdf`)
                */
                //downArticleFileFromServer(file.fileId, "111.pdf");
            }
        },
        created() { 
            this.$nextTick(()=>{  
                setTimeout(() => {
                    this.getArticle(this.articleId);
                }, 200);            
                
            });
        },
        mounted() {
          
        },
        watch: {            
            articleId(val){      
                setTimeout(() => {
                    this.getArticle(this.articleId);
                }, 200);           
            }
        },
}
</script>
<style scoped>
.title-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    border-bottom: 1px solid #e6e6e6;
}
.title-box .title{
    font-size: 28px;
    line-height: 38px;    
    font-weight: bold;
  }

  .title-box .pubtime{
    font-size: 12px;    
    line-height: 24px;
    letter-spacing: 0;
    color: #5c5c5c;
    margin-top: 10px;
  }

  .breadcrumb-box{
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 10px;
}

/deep/ .el-breadcrumb__inner.is-link {
    font-weight: 400;
    text-decoration: none;
    -webkit-transition: color .2s cubic-bezier(.645,.045,.355,1);
    transition: color .2s cubic-bezier(.645,.045,.355,1);
    color: #909399;
}

.paragraph-list{
    padding: 10px 0;
}

/deep/ .paragraph-list p{
    text-indent: 2em!important;
    -webkit-margin-before: 10px!important;
    margin-block-start: 10px!important;
    -webkit-margin-after: 10px!important;
    margin-block-end: 10px!important;
    font-size: 16px;
    line-height: 32px;
    text-align: justify;
    letter-spacing: 0;
}

/deep/ .paragraph-list p[style*="text-align:center"]{
    text-indent: 0em !important;
}

/deep/.paragraph-list p img{
    max-width: 680px !important;
    width: 100% !important;
    margin: 0 auto !important;
    height: auto;
    object-fit: contain;
    display: block !important;
    border: none !important;
}

/deep/.paragraph-list p video{
    max-width: 680px !important;
    height: auto !important;   
    margin: 0 auto !important;
    object-fit: contain;     
}

/deep/ .firstRow td{
    background-color: #EBEEF5;
    font-weight: bold;
}

/deep/ table, tr{
    font-size: 14px;
    
}

/deep/ td{
    border: 1px solid #EBEEF5 !important;
    padding: 10px;
}

.filelist-box-title{
    padding: 10px;
    margin-top: 20px;    
    font-weight: bold;
    background: rgb(112, 0, 5);
    color: #ffffff;
}

.filelist-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.filelist-item .file-op{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100px;
    font-size: 12px;
    text-align: right;
}

.filelist-item .file-op .el-link{    
    font-size: 12px !important;    
}

a{
    color: #000000;
}

/deep/ a:hover, a:focus {
    color: #9a0000 !important;
    text-decoration: none !important;
}


</style>
