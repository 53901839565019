<template>
  <div id="app">
    <!--<Header></Header>-->
    <router-view/>
  </div>
</template>

<script>
//import Header from "@/components/header";
export default {
  name: 'App',
  components:{
    //Header,
  },
  created(){
    this.$store.dispatch('account/checkLogin').then(()=>{});
    //this.$store.commit('account/checkLogin').then(()=>{});
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
