<template>
  <div class="main-container">
      <Header :forceLogin="false"></Header>
      <div class="content-container">
        <div class="login-wrapper">
          <div>登录</div>
          <el-form
            :model="loginForm"
            :rules="rules"
            ref="loginForm"
            label-width="100px"
          >
            <div>
              <el-form-item prop="userName" label-width="0">
                <el-input
                  v-model="loginForm.userName"
                  placeholder="北大职工号/北大邮箱/手机号"
                ></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item prop="password" label-width="0">
                <el-input
                  v-model="loginForm.password"
                  placeholder="密码"
                  show-password
                ></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="0">
                <el-checkbox v-model="loginForm.remember">记住登录</el-checkbox>
              </el-form-item>
            </div>
          </el-form>
          <div class="submit-wrapper">
            <el-button type="danger" class="submit-btn" @click="handleSubmit"
              >登录</el-button
            >
          </div>
        </div>
      </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { loginByName } from "@/api/loginApi";
import { getCurrentUserInfo } from "@/api/userApi";
import $ from "jquery";
export default {
  components: { Header, Footer },
  props: {},
  data() {
    return {
      loginForm: { userName: null, password: null, remember: 0 },
      rules: {
        userName: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
          { min: 1, max: 50, message: "长度在 1 到 50 个字符", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
          { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    getUser() {
      getCurrentUserInfo().then(res => {
        if (res.code != 200) {
          return;
        }
        this.$store.commit("account/changLogin", {
          isLogin: true,
          userInfo: res.data.user
        });

        // setTimeout(()=>{
          this.$router.push("/");
        // }, 500);

      });
    },
    handleSubmit() {
      this.$refs["loginForm"].validate(valid => {
        if (valid) {
          loginByName({
            loginid: this.loginForm.userName,
            password: this.loginForm.password,
            remember: this.loginForm.remember
          }).then(result => {
            if (result.code != 200) {
              this.$message.error(result.msg);
              return;
            }
            localStorage.setItem("access-token", result.data.access_token);
            this.getUser();
          });
        } else {
          return false;
        }
      });
    }
  },
  created() {},
  mounted() {
    var self = this;
    document.onkeydown = function(e) {
      let ev = document.all ? window.event : e
      if (ev.keyCode === 13) {
        self.handleSubmit()
      }
    }
  }
};
</script>

<style scoped>
  .content-container{
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login-main {
  min-height: 100%;
  display: flex;
  justify-content: center;
}
.login-wrapper {
  margin: 60px 0px;
  width: 300px;
  display: flex;
  align-items: start;
  flex-direction: column;
  padding: 0px 15px;
}
.login-wrapper > div:first-child {
  text-align: center;
}
.login-wrapper > .el-form {
  width: 100%;
}
.login-wrapper > div:first-child,
.login-wrapper > .el-form > div {
  margin-bottom: 20px;
  width: 100%;
}
/deep/.el-form-item__content {
  margin-left: 0px !important;
}
.submit-btn {
  background: rgba(148, 8, 11, 1);
  width: 200px;
  outline: none;
  border: none;
}
.submit-btn:hover {
  opacity: 0.8;
}
.submit-wrapper {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}
</style>
