// 获取会议类型名称
function getMeetingTypeName(type){
    let name = "其他会议";
    if(type == 0){
        name = "周期性讨论班";
    }else if(type == 1){
        name = "学术会议";
    }else if(type == 3){
        name = "所内部工作会议";
    }
    else if(type == 4){
      name = "所内业务交流（限所内老师）";
    }
    return name;
}

export{
    getMeetingTypeName,
}