
/**
 * 判断用户是否具有某角色
 * @param {*} userInfo 
 * @param {*} roleKey 
 * @returns 
 */
function isRole(userInfo, roleKey){
    if(userInfo == null || userInfo.roles == null){
      return false;
    }
    
    if(!userInfo.roles){
      return false;
    }

    for(let u in userInfo.roles){
      if(userInfo.roles[u].roleKey == roleKey){
        return true;
      }
    }

    return false;
}

// 获取当前登录用户信息
function getCurrUser(){
    return null;
}

export default {
    isRole,
    getCurrUser,
}