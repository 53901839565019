<template>
  <div class="booking-step-container">
    <div class="select-room" v-if="selectedRoom ? true : false">
      <span>{{ selectedRoom.building }}</span>
      <el-dropdown @command="setForBookingRoom">
        <span class="el-dropdown-link">
          {{ selectedRoom.name
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :command="room"
            v-for="room in meetingRooms"
            :key="room.id"
            ><label>{{ room.name }}</label
            >/<span
              >{{ room.building }}{{ room.floor }}层</span
            ></el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="select-date">
      <div class="prevday" @click="toPrevDay">
        <i class="el-icon-arrow-left"></i><span>前一天</span>
      </div>
      <vue-datepicker-local
        v-model="pickerDate"
        placeholder="请选择预定日期"
        format="YYYY年MM月DD日"
        :disabled-date="disabledDate"
      />
      <div class="nextday" @click="toNextDay">
        <span>后一天</span><i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="select-times">
      <div class="time-slices-wrapper" v-for="(times, index) in timeSlices"
        :key="index">
        <div v-if="times.status == 1" class="booked-row">
          <div class="time">{{ times.startTime }}-{{ times.endTime }}</div>
          <div>已预定</div>
          <div class="user-info">
            <span class="user-name el-icon-s-custom">{{times.userName}}</span>
          </div>
        </div>
        <div v-if="times.status == 2" class="booked-row">
          <div class="time">{{ times.startTime }}-{{ times.endTime }}</div>
          <div>已锁定</div>          
        </div>
        <div v-if="times.status == 3" class="booked-row">
          <div class="time">{{ times.startTime }}-{{ times.endTime }}</div>
          <div></div>          
        </div>
        <div v-if="times.status == 0" class="idle-row">          
          <div
            class="time"
            :class="times.isActive ? 'activeTime' : ''"
            @click="setBookingTime(times)"
            >
            <span style="margin-right: 5px;"
              :class="times.isActive ? 'el-icon-check' : ''"></span>
            <span>{{ times.startTime }}-{{times.endTime}}</span>
          </div>

          <span v-if="times.desc ? true : false" class="times-desc">{{
            times.desc
          }}</span>
          <span v-else class="idel-desc">空闲中</span>
        </div>
      </div>
    </div>
    <div class="next-wrapper">
      <el-button type="danger" class="nextstep-btn" @click="handleNextstep"
        >下一步</el-button
      >
    </div>
  </div>
</template>

<script>
import VueDatepickerLocal from "vue-datepicker-local";
import { getMeetingRooms, getMeetingRoomIntervals, getBookingDateRange, } from "@/api/meetingRoomApi";
import { parseTime } from "@/utils/wmeeting";

export default {
  components: { VueDatepickerLocal },
  props: {
    forbooking: null
  },
  data() {
    return {
      meetingRooms: null,
      selectedRoom: null,
      bookingDateRange: null,
      pickerDate: new Date(),
      timeSlices: [],      
    };
  },
  methods: {
    loadMeetingRooms() {
      // 获取允许预定的时间范围
      getBookingDateRange().then(result => {
        if (result.code != 200) {
          return;
        }        
        this.bookingDateRange = result.data;
      });

      // 获取会议室
      getMeetingRooms().then(result => {
        if (result.code != 200) {
          return;
        }
        this.meetingRooms = result.data;
      });
    },
    computeDays(date1, date2) {
      var time1 = date1.getTime();
      var time2 = date2.getTime();
      var diff = time2 - time1;
      var days = Math.floor(diff / (24 * 3600 * 1000));

      return Math.abs(days);
    },


    // 是否在允许预定的时间范围
    enableBookingTime(date){
      //console.log(this.bookingDateRange.startTime.replace(/-/g, '/'), date);
      let startDay = new Date(this.bookingDateRange.startTime.replace(/-/g, '/'));
      let endDay = new Date(this.bookingDateRange.endTime.replace(/-/g, '/'));

      // 锁定需要精确到分钟，因此按日精度来判断就不合适了。 2023-5-12
      /*
      let dateStr = parseTime(date, "{y}-{m}-{d}");
      let timeStamp = new Date(dateStr + " 00:00:00").getTime()/1000;
      
      let ret = true;
      if(this.selectedRoom != null && this.selectedRoom.lockList != null){
        this.selectedRoom.lockList.forEach(item => {
          if( timeStamp >= item.startDate && timeStamp <= item.endDate){
            ret = false;
            return false;
          }
        });
      }
      if(ret == false){
        return false;
      }
      */
        
      if(date.getTime() >= startDay.getTime() && date.getTime() <= endDay.getTime()){
        return true;
      }
      return false;
    },


    handleNextstep() {
      var result = this.getStartEndTime();
      if (!result.first) {
        this.$message.error("请选择预约时段");
        return;
      }

      
      //var date = new Date();
      //var diff = this.computeDays(this.pickerDate, date);
      if(!this.enableBookingTime(this.pickerDate)){
        this.$message.error("该会议室在选择的时间中不允许预定，请重新选择日期");
        return;
      }
      

      this.$emit("gotoStep2", this.selectedRoom, this.pickerDate, {
        first: result.first,
        second: result.second
      });
    },
    getStartEndTime() {
      var startTime = null;
      var endTime = null;
      var startElem = null;
      this.timeSlices.forEach(element => {
        if (element.isActive) {
          if (!startTime) {
            startTime = element;
          } else {
            endTime = element;
          }
        }
      });

      return { first: startTime, second: endTime };
    },
    check(first, second) {
      for (var i = 0; i < this.timeSlices.length; i++) {
        if (
          this.timeSlices[i].startTimeInt >= first.startTimeInt &&
          this.timeSlices[i].startTimeInt <= second.startTimeInt
        ) {
          if (this.timeSlices[i].status == 1) {
            return false;
          }
        }
      }
      return true;
    },
    setActives(first, second) {
      if (!first || !second) {
        return;
      }
      this.timeSlices.forEach(element => {
        if (
          element.startTimeInt >= first.startTimeInt &&
          element.startTimeInt <= second.startTimeInt
        ) {
          element.isActive = true;
          element.desc = "已选择";
        } else {
          element.isActive = false;
          element.desc = null;
        }
      });
    },
    setBookingTime(times) {
      times.isActive = times.isActive ? false : true;
      if (times.isActive) {
        times.desc = "已选择";
      } else {
        times.desc = null;
      }
      this.$forceUpdate();

      var result = this.getStartEndTime();
      if (!result.first || !result.second) {
        return;
      }

      if (!this.check(result.first, result.second)) {
        times.isActive = false;
        times.desc = null;
        this.$forceUpdate();
        return;
      }

      this.setActives(result.first, result.second);
      this.$forceUpdate();
    },
    toPrevDay() {
      this.pickerDate = new Date(
        this.pickerDate.getTime() - 24 * 60 * 60 * 1000
      );
    },
    toNextDay() {
      this.pickerDate = new Date(
        this.pickerDate.getTime() + 24 * 60 * 60 * 1000
      );
    },
    loadRoomIntervals(roomId, bookingDate) {
      let time = this.parseTimeStamp(bookingDate);
      getMeetingRoomIntervals(roomId, time).then(result => {
        if (result.code != 200) {
          return;
        }
        this.timeSlices = result.data;
      });
    },
    parseTimeStamp(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      let ts = date.getTime();

      return Math.floor(ts / 1000);
    },
    setForBookingRoom(room) {
      //console.log(room);
      this.selectedRoom = room;
      this.loadRoomIntervals(this.selectedRoom.id, this.pickerDate);
    },

    disabledDate(date){      
      return !this.enableBookingTime(date);
    }
  },
  created() {
    this.loadMeetingRooms();
  },
  watch: {
    pickerDate(newVal, oldVal) {
      this.pickerDate = newVal;
      this.loadRoomIntervals(this.selectedRoom.id, this.pickerDate);
    }
  },
  mounted() {},
  computed:{ }
};
</script>

<style scoped>
.booking-step-container {
  width: 100%;
}

.select-times {
  margin-top: 25px;
  width: 100%;
  border: 1px solid #e4e7ed;
}
.time-slices-wrapper {
  height: 30px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e4e7ed;
  padding: 15px;
  margin: 0px 20px;
}
.idle-row .time {
  cursor: pointer;
  outline: none;
}
.idle-row .time:focus {
  outline: none;
}

.idle-row .activeTime {
  background: rgba(148, 8, 11, 1) !important;
  color: #fff;
  border: none !important;
}

.time-slices-wrapper:hover {
  background: rgba(148, 8, 11, 0.1) !important;
  color: #fff;
  border: none !important;
}


.idle-row .idel-desc {
  color: rgba(148, 8, 11, 1);
}


.booked-row .time,
.idle-row .time {
  width: 150px;
  height: 35px;
  margin-right: 20px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.times-desc {
  color: #333 !important;
}
.booked-row .time {
  background: rgba(204, 204, 204, 1);
}

.booked-row .user-info{
  flex-grow: 1;
  text-align: right;
}

.booked-row .user-name{
  font-size: 12px;
  color: #909399;
}

.idle-row .time {
  background: #fff;
  border: 1px solid rgba(153, 153, 153, 1);
}
.booked-row,
.idle-row {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #333333;
}
.select-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
.select-date .prevday,
.select-date .nextday {
  width: 100px;
  border: 1px solid #e4e7ed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(242, 242, 242, 1);
  cursor: pointer;
  color: #606266;
}
.select-date .prevday:hover,
.select-date .nextday:hover {
  opacity: 0.8;
}
.select-date .prevday span,
.select-date .nextday span {
  margin-bottom: 4px;
}
.select-room {
  width: 100%;
  height: 50px;
  border: 1px solid #e4e7ed;
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: #606266;
  justify-content: space-between;
}
.select-room span {
  margin-left: 15px;
}
.select-room .el-dropdown {
  margin-right: 15px;
  cursor: pointer;
}
.nextstep-btn {
  background: rgba(148, 8, 11, 1);
  width: 200px;
  outline: none;
  border: none;
}
.nextstep-btn:hover {
  opacity: 0.8;
}
.next-wrapper {
  margin-top: 20px;
  text-align: center;
}
</style>
