
function isEmptyString(val){
    if(val != null && (val+'').length > 0){
        return false;
    }
    return true;
}

export default{
    isEmptyString,
}