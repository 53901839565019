<template>
    <div>
        <div align="center" style="color:#BB0000;font-family:Microsoft Yahei;font-size: 24px; font-weight: bold; margin: 20px 20px 20px 20px;">
            <p>正在转向北京大学身份认证系统......</p>
            <form action="https://iaaa.pku.edu.cn/iaaa/oauth.jsp" method="post" name="formx1" id = "loginForm" style="display:none;">
                <input type=hidden name="appID" value="wict_schedule"/>
                <input type=hidden name="redirectUrl" value="http://schedule.wict.pku.edu.cn/callback/pkusso"/>            
            </form>
        </div>                     
    </div>
</template>
<script>
export default {  
  props: {},
  data() {
    return {      
    };
  },
  methods: {
    
  },
  created() {    
    setTimeout(() => {
        let fm = document.getElementById("loginForm");
        fm.submit();
    }, 200);    
  },
  mounted() {}
};
</script>
<style scoped>

</style>