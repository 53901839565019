import Vue from "vue"
import axios from 'axios';
import jsonbig from "json-bigint";
import { saveAs } from 'file-saver';
import {blobValidate, tansParams} from "./wmeeting";
import {Loading, Message} from 'element-ui'

let downloadLoadingInstance;

axios.defaults.timeout = 300000;
axios.defaults.withCredentials = true;
axios.defaults.transformResponse = [
  function (data, headers) {
    const contentType = headers["content-type"];
    if (contentType && contentType.includes("application/json")) {
      const json = jsonbig({
        storeAsString: true,
      });
      return json.parse(data);
    }
    return data;
  },
];

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 300000
})


//http request 拦截器
service.interceptors.request.use(
  config => {
    // config.data = JSON.stringify(config.data);
    config.headers = {
      'Content-Type':'application/json; charset=UTF-8'
    }
    const token = encodeURIComponent(localStorage.getItem("access-token"));
    
    if (token) {
        config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    return Promise.reject(err);
  }
);


//http response 拦截器
service.interceptors.response.use(
  response => {
    if(response.data.errCode == 401){
      router.push({
        path:"/login",
      })
    }
    return response;
  },
  error => {
    return Promise.reject(error)
  }
)


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function fetch(url, params={}){
  return new Promise((resolve,reject) => {
    service.get(url,{
      params:params
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      reject(err)
    })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
 export function post(url,data = {}){
   return new Promise((resolve,reject) => {
    service.post(url, data)
          .then(response => {
            resolve(response.data);
          },err => {
            reject(err)
          })
   })
 }

 /**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url,data = {}){
  return new Promise((resolve,reject) => {
    service.patch(url,data)
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}

 /**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url,data = {}){
  return new Promise((resolve,reject) => {
    service.put(url,data)
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}

// if (_.indexOf(noTokenList, url) == -1) {
//   if (url === refreshTokenUrl) {
//     const RefreshToken = localStorage.getItem("refresh-token");
//     config.headers["refreshToken"] = RefreshToken;
//   } else {
//     const token = localStorage.getItem("access-token");
//     if (token) {
//       config.headers["Authorization"] = token;
//     }
//   }
// }

// 通用下载方法
export function download(url, params, filename) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", });
  return service.post(url, params, {    
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  }).then(async (data) => {
    const isLogin = await blobValidate(data);    
    if (isLogin) {
      const blob = new Blob([data], { type: 'application/octet-stream' })      
      saveAs(blob, decodeURI(filename))
    } else {
      const resText = await data.text();
      console.log("resText=", resText)
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}

export function downloadAsFile(url, data = {}) {
  return new Promise((resolve, reject) => {
    service.get(url, {
        params: data,
        responseType: "blob",
      })
      .then(
        (response) => {
          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          let fileName = decodeURIComponent(response.headers['content-disposition']);
          link.download = fileName.replace(/^attachment; filename=/, '');;
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        },
        (err) => {
          reject(err);
        }
      );
  });
}


export default service;